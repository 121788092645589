.valid-variable-blot {
  background-color: #e0e0e0;
  color: green;
  font-weight: bold;
  border-radius: 3px;
  padding: 0 2px;
}

.invalid-variable-blot {
  background-color: #e0e0e0;
  color: #d32f2f;
  font-weight: bold;
  border-radius: 3px;
  padding: 0 2px;
  text-decoration-line: underline;
  text-decoration-style: wavy;
  text-decoration-color: red;
}