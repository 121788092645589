@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    font-family: "Lato", sans-serif !important;
}

body {
    margin: 0;
    padding: 0;
}

.disabled-row {
    background-color: #f5f5f5;
    color: #bbbbbb;
    pointer-events: none;
}

.disabled-row .ant-checkbox-wrapper {
    display: none;
}

.ant-table table {
    font-family: "Lato", sans-serif;
}

.ant-table-footer {
padding: 12px !important;
}