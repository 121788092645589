.nav-menu > div {
  display: flex;
  align-items: center;
  font-family: '"Lato", sans-serif';
}

.nav-menu span[role="img"] {
  padding-left: 22px;
  padding-right: 10px;
}

.nav-menu span,
.nav-menu a {
  font-family: '"Lato", sans-serif';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
  padding-right: 20px;
  text-decoration: none;
}
